
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import moment from "moment";
import _ from "lodash";

export default defineComponent({
  name: "student-academics",
  components: {
  },
  setup() {
    const route = useRoute();
    const id : number = +route.params.id; 
    const classes = ref<any>([]);
    const semesterListData = ref<any>([]);
    const selectedSemester = ref(null as any);
    const studentAcademics = ref<any>([]);

    const getClasses = async() => {
        await new Promise((resolve, reject) => {
            ApiService.setHeader();    
            ApiService.get("class-student/subjects/detail/"+id)
                .then(({ data }) => {
                  semesterListData.value = [];
                  data.data.forEach(classData => {
                    let semester = classData.subject.session.level.program.semester;
                    if(semesterListData.value.filter(x=>x.id ===semester.id).length === 0){
                      semesterListData.value.push(semester);
                    }
                  });

                  semesterListData.value = semesterListData.value.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });

                  if(semesterListData.value.length > 0){
                    selectedSemester.value = semesterListData.value[0].id;
                  }

                  classes.value = data.data;
                  resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const sessions = computed(() => {
        let data = classes.value.filter(x=>x.subject.session.level.program.semester_id == selectedSemester.value);
        let sessionGroup = _.groupBy(data, data => data.subject.session_id); 
        
        return sessionGroup;
    });

    onBeforeMount(async() =>{
      await getClasses();
    }) 

    onMounted(() => {
      setCurrentPageBreadcrumbs("Academics", ["Student"]);
    });

    return {
      studentAcademics,
      semesterListData,
      selectedSemester,
      classes,
      moment,
      sessions
    };
  },
});
