<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Academic history</h3>
      </div>
    </div>
    <div class="card-body border-top p-9" v-if="!classes">
      No history!
    </div>
    <div class="card-body border-top p-9" v-else>
      <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
        <el-select
            class="form-select-solid w-100"
            placeholder="Select semester"
            v-model="selectedSemester"
        >
          <el-option 
            v-for="semester in semesterListData" 
            :label="semester.name+'/'+semester.code+'/'+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')" 
            :value="semester.id"
            :key="semester">
            {{semester.name+'/'+semester.code+'/'+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')}}
          </el-option>
        </el-select>
      </div>
      <div class="p-5 mb-5 mb-xl-10" id="studnet_academics" v-for="(items,index) in sessions" :key="index">
        <h3 class="align-items-start flex-column" style="display: block;">
          <span class="fw-bolder fs-3 mb-1" style="display:inline-block;">{{items[0].subject.session.name}}({{items[0].subject.session.code}}) : {{items[0].subject.session.level.program.semester.name}} > {{items[0].subject.session.level.program.name}} > {{items[0].subject.session.level.name}}</span>
          <span v-if="moment(items[0].subject.session.level.program.semester.end_date) <= moment() && items[0].pass_fail" style="display:inline-block;padding-right:10px;">
            <span v-if="items[0].pass_fail == 'PASS'" style="color:#50CD89"><img class="pass-icon" src="/media/icons/duotune/general/gen037.svg" />{{items[0].pass_fail}}</span>
            <span v-else style="color:red;"><img class="fail-icon" src="/media/icons/duotune/general/gen034.svg" /> {{items[0].pass_fail}}</span>
          </span>
        </h3>
        <div class="p-2" v-for="subject in items" :key="subject"> 
          <h3>{{subject.subject.name}}</h3>
          <h4 style="font-weight:400;">Attendance Rate : {{subject.attendance_rate}} %</h4>
          <h4 style="font-weight:400;">Assessment scores</h4>
          <el-table :data="subject.student_grades">
            <el-table-column prop="assessment.skill_area" label="Skill Area"></el-table-column>
            <el-table-column prop="assessment.title" label="Title"></el-table-column>
            <el-table-column prop="achieved_point" label="Score">
              <template v-slot="scope">{{scope.row.achieved_point}}/{{scope.row.assessment.max_point}}</template>
            </el-table-column>
            <el-table-column prop="" label="%">
              <template v-slot="scope">{{(scope.row.achieved_point/scope.row.assessment.max_point*100).toFixed(2)}}%</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import moment from "moment";
import _ from "lodash";

export default defineComponent({
  name: "student-academics",
  components: {
  },
  setup() {
    const route = useRoute();
    const id : number = +route.params.id; 
    const classes = ref<any>([]);
    const semesterListData = ref<any>([]);
    const selectedSemester = ref(null as any);
    const studentAcademics = ref<any>([]);

    const getClasses = async() => {
        await new Promise((resolve, reject) => {
            ApiService.setHeader();    
            ApiService.get("class-student/subjects/detail/"+id)
                .then(({ data }) => {
                  semesterListData.value = [];
                  data.data.forEach(classData => {
                    let semester = classData.subject.session.level.program.semester;
                    if(semesterListData.value.filter(x=>x.id ===semester.id).length === 0){
                      semesterListData.value.push(semester);
                    }
                  });

                  semesterListData.value = semesterListData.value.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });

                  if(semesterListData.value.length > 0){
                    selectedSemester.value = semesterListData.value[0].id;
                  }

                  classes.value = data.data;
                  resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const sessions = computed(() => {
        let data = classes.value.filter(x=>x.subject.session.level.program.semester_id == selectedSemester.value);
        let sessionGroup = _.groupBy(data, data => data.subject.session_id); 
        
        return sessionGroup;
    });

    onBeforeMount(async() =>{
      await getClasses();
    }) 

    onMounted(() => {
      setCurrentPageBreadcrumbs("Academics", ["Student"]);
    });

    return {
      studentAcademics,
      semesterListData,
      selectedSemester,
      classes,
      moment,
      sessions
    };
  },
});
</script>

<style>
  .pass-icon {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
  }

  .fail-icon{
     filter:invert(80%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
  }
</style>